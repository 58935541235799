<template>
  <div>
    <el-upload
      id="upimg"
      v-show="false"
      class="upload-demo"
      :action="UploadUrl"
      :headers="header"
      :data="data"
      :on-success="handleSuccess"
      multiple
    >
      <el-button size="small" type="primary">点击上传</el-button>
    </el-upload>
    <quill-editor
      ref="QuillEditor"
      :options="editorOption"
      v-model="content"
      type="text"
      @input="handlechange"
    >
    </quill-editor>
  </div>
</template>

<script>
let Host = require("@/libs/Config")
// 工具栏配置
const toolbarOptions = [
  ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
  ['blockquote', 'code-block'],

  [{ 'header': 1 }, { 'header': 2 }],               // custom button values
  [{ 'list': 'ordered' }, { 'list': 'bullet' }],
  [{ 'script': 'sub' }, { 'script': 'super' }],      // superscript/subscript
  [{ 'indent': '-1' }, { 'indent': '+1' }],          // outdent/indent
  [{ 'direction': 'rtl' }],                         // text direction

  [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
  [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

  [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
  [{ 'font': [] }],
  [{ 'align': [] }],
  ['link', 'image', 'video'],
  ['clean']                                         // remove formatting button
]
const titleConfig = {
  'ql-bold': '加粗',
  'ql-color': '颜色',
  'ql-font': '字体',
  'ql-code': '插入代码',
  'ql-italic': '斜体',
  'ql-link': '添加链接',
  'ql-background': '背景颜色',
  'ql-size': '字体大小',
  'ql-strike': '删除线',
  'ql-script': '上标/下标',
  'ql-underline': '下划线',
  'ql-blockquote': '引用',
  'ql-header': '标题',
  'ql-indent': '缩进',
  'ql-list': '列表',
  'ql-align': '文本对齐',
  'ql-direction': '文本方向',
  'ql-code-block': '代码块',
  'ql-formula': '公式',
  'ql-image': '图片',
  'ql-video': '视频',
  'ql-clean': '清除字体样式',
  'ql-upload': '文件'
}
export default {
  props: {
    /**
     * 上传类型
     */
    updFileModule: {
      type: String,
      default: "activity_call"
    }
  },
  props: {
    content: {
      type: String,
      default: "",
    }
  },
  model: {
    props: "value",
    event: "input",
  },
  data () {
    return {
      content: '',
      UploadType: "",
      UploadUrl: "",
      header: {},
      data: {},
      files: [],
      editorOption: {
        modules: {
          toolbar: {
            container: toolbarOptions,  // 工具栏
            handlers: {
              'image': function (value) {
                HandleImg(value)
              },
              "video": function (value) {
                HandleVideo(value)
              },
            }
          }
        }
      }
    }
  },
  methods: {
    HandleImg: function (value) {
      if (value) {
        this.UploadType = "img"
        this.UploadUrl = Host.Host + "hw/upload/files"
        this.header = {
          "X-Auth-Token": this.lsGet("userInfo").token,
        }
        this.data = {
          "sortVal": 1,
          "updFileModule": this.updFileModule,
          "updFileType": 'pic',
        }

        document.querySelector('#upimg input').setAttribute("accept", ".jpg,.png,.jpeg")
        document.querySelector('#upimg button').click()
      } else {
        this.quill.format('image', false)
      }
    },
    HandleVideo (value) {
      if (value) {
        this.UploadType = "video"
        this.UploadUrl = Host.Host + "hw/upload/files"
        this.header = {
          "X-Auth-Token": this.lsGet("userInfo").token,
        }
        this.data = {
          "sortVal": 1,
          "updFileModule": this.updFileModule,
          "updFileType": 'video',
        }
        document.querySelector('#upimg input').setAttribute("accept", ".mp4,.WebM,.Ogg")
        document.querySelector('#upimg button').click()
      } else {
        this.quill.format('image', false)
      }
    },
    /**
     * 上传成功回调
     */
    handleSuccess (res) {
      let { updId, updUrl } = res.data
      // 获取富文本组件实例
      let quill = this.$refs.QuillEditor.quill
      if (res) { // 如果上传成功
        // 获取光标所在位置
        let length = quill.getSelection().index
        // 插入图片，res为服务器返回的图片链接地址
        if (this.UploadType == "img") {
          quill.insertEmbed(length, 'image', updUrl)
        } else {
          quill.insertEmbed(length, 'video', updUrl)
        }
        this.files.push(updId)
        this.$emit("UploadFiles", this.files)
        // 调整光标到最后
        quill.setSelection(length + 1)
      } else {
        // 提示信息
        this.$message.error('图片插入失败')
      }
    },
    /**
     * 富文本框值被改变事件
     */
    handlechange (even) {
      let quill = this.$refs.QuillEditor.quill
      let value = document.querySelector('.ql-container .ql-editor').innerHTML
      this.$emit('input', value)
    },
    /**
     * 添加富文本框中文注解
     */
    addQuillTitle () {
      const oToolBar = document.querySelector('.ql-toolbar'),
        aButton = oToolBar.querySelectorAll('button'),
        aSelect = oToolBar.querySelectorAll('select')
      aButton.forEach(function (item) {
        if (item.className === 'ql-script') {
          item.value === 'sub' ? item.title = '下标' : item.title = '上标'
        } else if (item.className === 'ql-indent') {
          item.value === '+1' ? item.title = '向右缩进' : item.title = '向左缩进'
        } else {
          item.title = titleConfig[item.classList[0]]
        }
      })
      aSelect.forEach(function (item) {
        item.parentNode.title = titleConfig[item.classList[0]]
      })
    },
  },
  mounted () {
    window.HandleImg = this.HandleImg
    window.HandleVideo = this.HandleVideo
    this.addQuillTitle()
    window.quill = this.$refs.QuillEditor.quill
  },
}
</script>

<style>
.quill-editor {
  height: 50vh;
}
</style>